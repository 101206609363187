import { graphql } from "gatsby";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import Layout from "../../../components/nigeria/layout";
import { Nigeria_MetaTags } from "../../../helpers/constants";
import HeroImage from "../../../images/nigeria/payment/payment-hero.png";
import { ArrowRightShortIcon, CollapseMenuIcon, ExpandMenuIcon } from "../../../images/nigeria/Icons";
import MiddleHeaderImage from "../../../images/nigeria/personal-banking/middle-header.png";

import PaymentWidget1 from "../../../images/nigeria/payment/payment-widget-1.svg";
import PaymentWidget2 from "../../../images/nigeria/payment/payment-widget-2.svg";
import PaymentWidget3 from "../../../images/nigeria/payment/payment-widget-3.svg";

import { LayoutCard, MerchantContainerDiv } from "./banking";

const PaymentPage = () => {
  const { t } = useTranslation();

  return (
    <Layout
      pageTitle={Nigeria_MetaTags.personalBanking.payment.title}
      description={Nigeria_MetaTags.personalBanking.payment.description}
      bankingType={'personal'}
    >
      <Helmet>
        <link rel="canonical"
          href="https://moniepoint.com/ng/personal/payment" />
        <link
          rel="alternate"
          href="https://moniepoint.com/ng/personal/payment"
          hreflang="x-default"
        />
        <link
          rel="alternate"
          href="https://moniepoint.com/ng/personal/payment"
          hreflang="en-ng"
        />
        <script type="application/ld+json">{`{"@context":"https://schema.org","@type":"FAQPage","mainEntity":[{"@type":"Question","name":"What bills can I pay with the Moniepoint app?","acceptedAnswer":{"@type":"Answer","text":"With the Moniepoint app, you can pay your electricity bill , waste bill , internet bill, streaming service and more\n"}},{"@type":"Question","name":"Is it possible to set up automatic bill payments?","acceptedAnswer":{"@type":"Answer","text":"Yes, you can schedule automatic bill payments in advance through the Moniepoint app."}},{"@type":"Question","name":"Can I purchase data and airtime for my mobile phone?","acceptedAnswer":{"@type":"Answer","text":"Yes, you can buy data or airtime for yourself or any other person through the mobile app"}},{"@type":"Question","name":"Are there any transaction fees associated with paying bills on the Moniepoint app?","acceptedAnswer":{"@type":"Answer","text":"There is no extra transaction fee associated with paying your bills using the Moniepoint app"}},{"@type":"Question","name":"How quickly are payments processed?","acceptedAnswer":{"@type":"Answer","text":"Payments are processed immediately through the Moniepoint app"}},{"@type":"Question","name":"Can I view my payment history and transaction receipts through your platform for record-keeping purposes?","acceptedAnswer":{"@type":"Answer","text":"Yes, you can view your payment history and access transaction history through the Moniepoint app or your online account. This feature is useful for record-keeping and tracking your financial transactions."}}]}`}</script>

      </Helmet>
      <HeroContainer src={HeroImage}>
        <div className="hero-mask"></div>
        <div className="hero-content-wrapper">
          <div className="hero-content">
            <h1 className="hero-title font-grotesk">
              <Trans>payment_page.hero_title</Trans>
            </h1>
            {/* used for SEO tags*/}
            <h2 className={'d-none'}>
              Payment Solutions
            </h2>
            <p className="hero-subtitle">
              <Trans>payment_page.hero_subtitle</Trans>
            </p>
            <a
              href="https://app.adjust.com/13owp4gc"
              target="_blank"
              className="btn btn-primary"
            >
              <Trans>payment_page.features_cta</Trans>
              <ArrowRightShortIcon color={"white"} size={24} />
            </a>
          </div>
        </div>
      </HeroContainer>
      <ServiceAndTitle>

        <MiddleContainer src={MiddleHeaderImage}>
          <div className="content-main">
            <div className="content">
              <NoHiddenFees>No hidden fees or charges</NoHiddenFees>
              <div className="middle-header">

                Easy
                <span className={'middle-header-million'}> refunds</span> and
                <span className={'middle-header-million'}> reversals</span>,
                <br className={'d-none d-md-block'} /> at the touch of a button.
              </div>
              <div>
                <p className={'middle-desc'}>
                  Say goodbye to failed transactions and unreversed debits. Manage and
                  <br className={'d-none d-sm-block'} /> monitor refunds
                  on failed transactions within the app.
                </p>
              </div>
              <div className={'join'}>
                <a
                  href="https://app.adjust.com/13owp4gc"
                  target="_blank"
                  className="text-decoration-none"
                >
                  <span className={'border-bottom border-2 border-primary pb-2'}>
                    Open an account
                  </span>
                </a>
              </div>
            </div>
          </div>
        </MiddleContainer>

        <ServiceWrap>

          <MerchantContainerDiv>
            <div className={'content d-flex flex-column'}>

              <div className={'d-flex flex-md-row flex-sm-column layout-wrapper'}>

                <LayoutCard icon={PaymentWidget1}
                  desc={'Pay seamlessly at any Moniepoint business for free. All the speed, all the reliability, at 0 costs.'}
                  title={'Get more for less at any Moniepoint merchant'}
                />
                <LayoutCard icon={PaymentWidget2}
                  desc={'Activate your card from within your app. If it ever goes missing, No worries. Deactivate your card in-app without hassles.'}
                  title={'Transfer to Moniepoint or other bank users'}
                />
                <LayoutCard icon={PaymentWidget3}
                  desc={'Buy electricity units, pay utilities, renew your data subscription. We can’t stop the bills from coming, but we can make paying them as easy as possible.'}
                  title={'Pay bills, Buy airtime and data'}
                />


              </div>
            </div>
          </MerchantContainerDiv>

        </ServiceWrap>
      </ServiceAndTitle>
      <FAQsContainer>
        <div className="content">
          <h3 className="title">
            <Trans>FAQs</Trans>
          </h3>
          <div className="faqs-list">
            <FaqItem
              title="What bills can I pay with the Moniepoint app?">
              <p className="">With the Moniepoint app, you can pay your electricity bill , waste bill , internet bill, streaming service and
                more.</p>

            </FaqItem>
            <div className="faq-divider"></div>
            <FaqItem
              title="Is it possible to set up automatic bill payments?">
              <p>Yes, you can schedule automatic bill payments in advance through the Moniepoint app.</p>
            </FaqItem>
            <div className="faq-divider"></div>
            <FaqItem
              title="Can I purchase data and airtime for my mobile phone?">
              <p>Yes, you can buy data or airtime for yourself or any other person through the mobile app.</p>
            </FaqItem>
            <div className="faq-divider"></div>
            <FaqItem
              title="Are there any transaction fees associated with paying bills on the Moniepoint app?">
              <p className="">There is no extra transaction fee associated with paying your bills using the Moniepoint app.</p>

            </FaqItem>
            <div className="faq-divider"></div>
            <FaqItem
              title="How quickly are payments processed?">
              <p>Payments are processed immediately through the Moniepoint app.</p>
            </FaqItem>
            <div className="faq-divider"></div>
            <FaqItem
              title="Can I view my payment history and transaction receipts through your platform for record-keeping purposes?">
              <p>Yes, you can view your payment history and access transaction history through the Moniepoint app or your online account.
                This feature is useful for record-keeping and tracking your financial transactions.</p>
            </FaqItem>
          </div>
        </div>
      </FAQsContainer>
    </Layout>
  );
};

export default PaymentPage;


export const FaqItem = ({ title, children, open }) => {
  const [isOpen, setIsOpen] = useState(open || false);
  const toggleIsOpen = () => setIsOpen((value) => !value);
  return (
    <FAQItemConatainer>
      <div className="text-section">
        <div className="faq-title" onClick={toggleIsOpen}>
          <Trans>{title}</Trans>
        </div>
        <div className={`description ${isOpen && "open"}`}>
          {children}
        </div>
      </div>
      <div className="toggle-button" onClick={toggleIsOpen}>
        {isOpen ? <CollapseMenuIcon /> : <ExpandMenuIcon />}
      </div>
    </FAQItemConatainer>
  );
};

export const query = graphql`
  query {
    locales: allLocale(
      filter: { ns: { in: ["common", "nigeria"] }, language: { eq: "en" } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const HeroContainer = styled.section`
  background: url(${(props) => props.src});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  min-height: 497px;
  max-height: 575px;
  position: relative;

  .hero-mask {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    backdrop-filter: blur(1px);
    z-index: 1;
  }

  .hero-content-wrapper {
    height: 100%;
    max-width: 1440px;
    padding: 113px 149px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: end;
    z-index: 2;
    position: relative;
    @media only screen and (max-width: 1024px) {
      padding: 140px 60px;
    }
  }

  .hero-content {
    color: white;

    .hero-title {
      font-family: "Founders Grotesk";
      font-size: 58px;
      font-weight: 500;
      line-height: 66px;
      letter-spacing: 0em;
      text-align: left;
      margin-bottom: 16px;
      max-width: 652px;
    }

    .hero-subtitle {
      font-size: 17px;
      font-weight: 400;
      line-height: 27px;
      letter-spacing: 0em;
      text-align: left;
      max-width: 476px;
      margin-bottom: 24px;
    }
  }

  @media only screen and (max-width: 768px) {
    height: 730px;
    background-size: cover;
    background-position: 70%;

    .hero-mask {
      background: linear-gradient(180deg,
      rgba(29, 29, 29, 0) 0%,
      rgba(29, 29, 29, 0.5) 79.14%);
    }

    .hero-content-wrapper {
      padding: 47px 20px;
    }

    .hero-content {
      .hero-title {
        font-size: 32px;
        line-height: 35px;
      }

      .hero-subtitle {
        font-size: 17px;
        line-height: 27.37px;
      }
    }
  }
`;

const MiddleContainer = styled.section`
  width: 100%;

  .content {
    margin: auto 16%;
    padding: 60px 0px 0;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    gap: 61px;
    position: relative;
    z-index: 1;
  }

  .middle-desc {
    color: var(--colors-neutral-black-100, #000);
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 25.6px */
  }

  @media only screen and (max-width: 1024px) {
    .content {
      margin: auto 7%;
    }
  }

  .content-main ::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: inherit;
    height: 100%;
    background: url(${(props) => props.src});
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    animation: rotateBackground 6s linear infinite;
    z-index: -100;
  }

  @keyframes rotateBackground {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .middle-header {
    color: #092256;
    text-align: center;
    font-family: Founders Grotesk;
    font-size: 64px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
  }

  .middle-header-million {
    color: #0361F0 !important;
  }

  .join {
    color: var(--colors-primary-blue-100, #0361F0);
    font-family: Founders Grotesk;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    width: 100%;
  }

  @media only screen and (max-width: 768px) {
    .content {
      padding: 48px 0 0 0;
      padding-right: 0px;
      margin: auto 20px auto 20px;
      gap: 32px;
    }

    .middle-header {
      font-size: 28px;
    }

    .join {
      font-size: 20px;
    }
  }
`


const ServiceAndTitle = styled.section`
  background: linear-gradient(180deg,
  rgba(242, 252, 255, 0) 0%,
  rgba(242, 252, 255, 0.5) 71.28%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 61px;
  text-align: center;
  font-size: 84px;
  color: #092256;

  .content {
    @media only screen and (max-width: 1024px) {
      padding: 131px 0px 0px;
      margin: auto 7%;
    }

    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    .serv2column {
      display: flex;
      flex-wrap: wrap;
      gap: 30px;
    }
  }
`;

const VectorParent = styled.div`
  width: 100%;
`;

const VectorIcon = styled.span`
  .vector-icon-img {
    position: absolute;
    margin-top: -150px;
    left: calc(50% - 312.27px);
    width: 624.93px;
    height: 686.68px;
    z-index: 1;
    animation: mymove 40s infinite;

    @media only screen and (max-width: 577px) {
      height: 250px;
      width: 250px;
      left: calc(50% - 125px);
      margin-top: -50px;
    }
  }
`;

const Businesses = styled.span`
  color: #0361f0;
`;

const AllYourFavouriteContainer = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 64px;
  font-size: 64px;
  font-weight: 600;
  z-index: 1;

  @media only screen and (max-width: 770px) {
    font-size: 48px;
    line-height: 1.15;
  }

  @media only screen and (max-width: 577px) {
    font-size: 32px;
    line-height: 1.2;
  }
`;

const GetAMoniepoint = styled.div`
  position: relative;
  font-weight: 500;
  font-size: 32px;

  @media only screen and (max-width: 577px) {
    font-size: 17px;
  }
`;

const LineDiv = styled.div`
  align-self: stretch;
  position: relative;
  border-top: 2px solid #0361f0;
  height: 2px;

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const GetAMoniepointCardParent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  z-index: 2;
  text-align: left;
  font-size: 32px;
  color: #0361f0;
  margin: 35px auto 0;

  @media only screen and (max-width: 768px) {
    font-size: 24px;
    gap: 8px;
  }
`;

/*const FrameChild5 = styled.span`
  .frame-child5-img {
    position: relative;
    width: 140px;
    height: 140px;
  }
`;
const YouProbablyWont = styled.div`
  align-self: stretch;
  position: relative;
  font-size: 16px;
  line-height: 160%;
  font-weight: 500;
  font-family: Inter;
  opacity: 0.9;
`;
const CardAndAccountSupportParent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 15px;
`;*/

const ServiceWrap = styled.div`
  width: 100%;
  text-align: initial;
`;

const ServiceBox = styled.div`
  border-radius: 16px;
  background-color: #fff;
  -webkit-box-shadow: 0px 1px 2px rgba(3, 97,  240,  0 .1);
  box-shadow: 0px 1px 2px rgba(3, 97,  240,  0 .1);
  border: 1px solid #e5e7e9;
  flex: 0 0 calc(50% - 0.9375rem);
  max-width: calc(50% - 0.9375rem);
  display: flex;
  flex-direction: column;
  padding: 40px;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 45px;
  color: #000;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  margin-bottom: 30px;

  &:hover {
    -webkit-box-shadow: -8px 20.25px 60px rgba(3, 97, 240, 0.16);
    box-shadow: -8px 20.25px 60px rgba(3, 97, 240, 0.16);
    transform: translateY(-4px);
  }

  @media only screen and (max-width: 577px) {
    flex: 0 0 100%;
    max-width: 100%;
    gap: 30px;
  }
`;

const ServiceInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 15px;
`;

const ServiceIcon = styled.span`
  .group-icon-img {
    position: relative;
    width: 140px;
    height: 140px;
    object-fit: cover;
  }
`;

const ServiceTitle = styled.div`
  font-family: "Founders Grotesk";
  align-self: stretch;
  position: relative;
  line-height: 120%;
  font-weight: 500;
  font-size: 40px;

  @media only screen and (max-width: 577px) {
    font-size: 28px;
  }
`;

const ServiceDescription = styled.div`
  position: relative;
  font-size: 16px;
  line-height: 160%;
  font-weight: 500;
  font-family: Inter;
  display: flex;
  align-items: center;
  opacity: 0.9;
`;

const ServiceBoxFull = styled.div`
  border-radius: 16px;
  background-color: #fff;
  -webkit-box-shadow: 0px 1px 2px rgba(3, 97,  240,  0 .1);
  box-shadow: 0px 1px 2px rgba(3, 97,  240,  0 .1);
  border: 1px solid #e5e7e9;
  display: flex;
  flex-direction: row;
  padding: 40px;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 60px;
  color: #000;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  width: 100%;

  &:hover {
    -webkit-box-shadow: -8px 20.25px 60px rgba(3, 97, 240, 0.16);
    box-shadow: -8px 20.25px 60px rgba(3, 97, 240, 0.16);
    transform: translateY(-4px);
  }

  @media only screen and (max-width: 577px) {
    flex-direction: column;
    gap: 30px;
  }
`;

const NoHiddenFees = styled.div`
  position: relative;
  line-height: 100%;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 16px;
  align-items: center;
  flex: auto;
  margin: 72px 0 40px;

  @media (max-width: 768px) {
    margin: 20px 0 20px;
  }
`;


const FAQsContainer = styled.section`
  background-color: white;

  .content {
    position: relative;
    max-width: 1440px;
    margin: auto;
    padding: 64px 150px;
    @media only screen and (max-width: 1024px) {
      padding: 64px 60px;
    }

    .title {
      font-family: "Founders Grotesk";
      font-size: 48px;
      font-weight: 500;
      line-height: 57px;
      letter-spacing: 0em;
      text-align: center;
      max-width: 554px;
      margin: 0 auto 48px;
      color: rgba(0, 0, 0, 1);
    }

    .faqs-list {
      width: 100%;

      .faq-divider {
        width: 100%;
        height: 1px;
        background: rgba(3, 87, 238, 0.1);
        margin: 32px 0;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .content {
      padding: 48px 20px;

      .title {
        font-size: 48px;
        line-height: 37.76px;
        margin: 0 auto 48px;
        max-width: 288px;
      }

      .faqs-list {
      }
    }
  }
`;

const FAQItemConatainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 24px;

  .toggle-button {
    height: 20px;
    width: 20px;
    cursor: pointer;
  }

  .text-section {
    .faq-title {
      max-width: 895px;
      font-family: "Founders Grotesk";
      font-size: 28px;
      font-weight: 500;
      line-height: 33px;
      letter-spacing: 0em;
      text-align: left;
      color: rgba(0, 0, 0, 1);
      margin-bottom: 0px;
      cursor: pointer;
    }

    .description {
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      color: rgba(0, 0, 0, 0.7);
      margin-bottom: 0px;
      display: none;

      &.open {
        display: block;
        margin-top: 16px;
      }

      ul.faq-list {
        padding-left: 32px;
        margin-top: 12px;

        li.faq-list-item {
          margin-bottom: 12px;
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    gap: 26px;

    .text-section {
    }
  }
`;


// const NoHiddenFees = styled.div`
// position: relative;
// line-height: 100%;
// text-transform: uppercase;
// font-weight: 600;
// font-size: 16px;
// align-items: center;
// flex: auto;
// margin: 72px 0 40px;
// `;
/*const ServiceBox = styled.div`
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 0px 1px 2px rgba(3, 97, 240, 0.1);
  border: 1px solid #e5e7e9;
  width: 554px;
  height: 428px;
  display: flex;
  flex-direction: column;
  padding: 40px;
  align-items: flex-start;
  justify-content: space-between;
  color: #000;
  &:hover {
    box-shadow: -8px 20.25px 60px rgba(3, 97, 240, 0.16);
  }
`;*/
